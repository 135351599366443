<template>
  <section class="section">
    <h2 class="section__title">Recovery code</h2>
    <div class="section__description">
      Enter one of your recovery codes in case you lost access to your mobile device.
    </div>
    <v-form ref="form" class="recovery-code-form">
      <v-text-field
        v-model="recoveryCode"
        background-color="white"
        label="Recovery code"
        placeholder=" "
        class="recovery-code-form__field"
        outlined
        :rules="fieldRules"
      />

      <div
        v-if="hasFormError"
        class="recovery-code-form__error-message mb-3 red--text text--accent-2"
      >
        {{ formErrorMessage }}
      </div>

      <v-btn
        tile
        large
        color="light-blue darken-4 mt-2"
        class="white--text"
        :loading="isLoading"
        @click="onSignIn"
      >
        Verify
      </v-btn>
    </v-form>
  </section>
</template>

<script>
import { mapActions } from 'vuex';

import types from '@/store/types';
import { NETWORK_ERROR } from '@/constants/errors';

const RECOVERY_FIELD_RULES = [v => !!v || 'This field is required'];

const FORM_ERRORS_MESSAGES = {
  CREDENTIALS_ERROR: 'Code verification failed. Please try again.',
  NETWORK_ERROR: 'Internet disconnected'
};

export default {
  name: 'RecoveryCodeForm',
  props: {
    username: {
      type: String,
      required: true
    },
    password: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fieldRules: RECOVERY_FIELD_RULES,
      recoveryCode: '',
      isLoading: false,
      hasFormError: false,
      formErrorMessage: ''
    };
  },
  methods: {
    ...mapActions({
      signIn: types.actions.SIGN_IN
    }),
    async validateForm() {
      this.hasFormError = false;

      await this.$nextTick();

      return this.$refs.form.validate();
    },
    async onSignIn() {
      const isFormValid = await this.validateForm();

      if (!isFormValid) {
        return;
      }

      this.isLoading = true;

      const { error } = await this.signIn({
        username: this.username,
        password: this.password,
        recoveryCode: this.recoveryCode
      });

      this.isLoading = false;

      if (error) {
        this.hasFormError = true;

        this.formErrorMessage =
          error === NETWORK_ERROR
            ? FORM_ERRORS_MESSAGES.NETWORK_ERROR
            : FORM_ERRORS_MESSAGES.CREDENTIALS_ERROR;

        return;
      }

      this.$router.push({ name: 'Dashboard' });
    }
  }
};
</script>

<style lang="scss" scoped>
.section {
  width: 400px;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);

  &__title {
    margin-bottom: 30px;
    font-size: 21px;
    line-height: 25px;
    color: #324752;
  }

  &__description {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 16px;
    color: #94a6b0;
  }
}

.recovery-code-form {
  margin: 0 auto;
  max-width: 320px;

  &__error-message {
    font-size: 12px;
  }
}
</style>
