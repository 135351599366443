<template>
  <login-page-template>
    <recovery-code-form :username="username" :password="password" />
  </login-page-template>
</template>

<script>
import LoginPageTemplate from '@/components/login-page/LoginPageTemplate.vue';
import RecoveryCodeForm from '@/components/login-page/RecoveryCodeForm.vue';

export default {
  name: 'RecoveryCodePage',
  components: { LoginPageTemplate, RecoveryCodeForm },
  props: {
    username: {
      type: String,
      default: ''
    },
    password: {
      type: String,
      default: ''
    }
  },
  mounted() {
    if (!this.username && !this.password) {
      this.$router.push({ name: 'SignInPage' });
    }
  }
};
</script>
